import React, { useEffect, useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Router from "./router";
import i18n from "./translation";

import GlobalStateContext from "./context/globalStateContext";
import UserContext from "./context/userContext";
import { useMediaQuery, useTheme } from "@mui/material";
import * as amplitude from "@amplitude/analytics-browser";
import ReactGA from "react-ga4";

// ReactGA.initialize("G-CP74KGE22R");

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    // if (process.env.NODE_ENV === "production") {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    // }
  }, [location]);

  return null;
};

// Define your types here
interface IGlobalState {
  // define your global state shape here
  [key: string]: any;
}

interface IUser {
  // define your user shape here
  [key: string]: any;
}

const App = () => {
  const [user, setUserContext] = useState<IUser | null>(null);
  const [globalState, setGlobalState] = useState<IGlobalState | null>(null);
  const muiTheme = createTheme();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    amplitude.init("540dba4c5c31b1e916705fcff3189c41");
    amplitude.track("Visited site", {
      isDesktop: isDesktop,
    });
  }, [isDesktop]);  

  useEffect(() => {
    setGlobalState({ ...globalState, isDesktop, startingUp: true });
  }, [isDesktop]);

  return (
    <BrowserRouter>
      <Analytics />
      <ThemeProvider theme={muiTheme}>
        <I18nextProvider i18n={i18n}>
          <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
            <UserContext.Provider value={{ user, setUserContext }}>
              <Router />
            </UserContext.Provider>
          </GlobalStateContext.Provider>
        </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
ReactDOM.render(<App />, document.getElementById("root"));
